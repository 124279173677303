// Aviko 1 Bon Voyage
import a1T from '../../assets/images/works/a1-t.jpg';
import a1 from '../../assets/images/works/a1.jpg';

// Cheetos 1  museum
import b1T from '../../assets/images/works/b1-t.jpg';
import b1 from '../../assets/images/works/b1.jpg';
import b2T from '../../assets/images/works/b2-t.jpg';
import b2 from '../../assets/images/works/b2.jpg';
import b3T from '../../assets/images/works/b3-t.jpg';
import b3 from '../../assets/images/works/b3.jpg';
import b4T from '../../assets/images/works/b4-t.jpg';
import b4 from '../../assets/images/works/b4.jpg';
import b5T from '../../assets/images/works/b5-t.jpg';
import b5 from '../../assets/images/works/b5.jpg';
import b6T from '../../assets/images/works/b6-t.jpg';
import b6 from '../../assets/images/works/b6.jpg';

// Fazer 1 win a tablet
import c1T from '../../assets/images/works/c1-t.jpg';
import c1 from '../../assets/images/works/c1.jpg';
import c2T from '../../assets/images/works/c2-t.jpg';
import c2 from '../../assets/images/works/c2.jpg';

// Geisha 1 kokomo
import d1T from '../../assets/images/works/d1-t.jpg';
import d1 from '../../assets/images/works/d1.jpg';
import d2T from '../../assets/images/works/d2-t.jpg';
import d2 from '../../assets/images/works/d2.jpg';

// Lays 1 movies
import e1T from '../../assets/images/works/e1-t.jpg';
import e1 from '../../assets/images/works/e1.jpg';
import e2T from '../../assets/images/works/e2-t.jpg';
import e2 from '../../assets/images/works/e2.jpg';
import e3T from '../../assets/images/works/e3-t.jpg';
import e3 from '../../assets/images/works/e3.jpg';

// Lays 2 football
import f1T from '../../assets/images/works/f1-t.jpg';
import f1 from '../../assets/images/works/f1.jpg';
import f2T from '../../assets/images/works/f2-t.jpg';
import f2 from '../../assets/images/works/f2.jpg';
import f3T from '../../assets/images/works/f3-t.jpg';
import f3 from '../../assets/images/works/f3.jpg';

// Geisha 2 Tallinn Dolls
import g1T from '../../assets/images/works/g1-t.jpg';
import g1 from '../../assets/images/works/g1.jpg';
import g2T from '../../assets/images/works/g2-t.jpg';
import g2 from '../../assets/images/works/g2.jpg';

// Tartu Mill 1 Jura
import h1T from '../../assets/images/works/h1-t.jpg';
import h1 from '../../assets/images/works/h1.jpg';

// Tartu Mill 2 Škoda
import i1T from '../../assets/images/works/i1-t.jpg';
import i1 from '../../assets/images/works/i1.jpg';

// Eesti Pagar 1 Sauna
import j1T from '../../assets/images/works/j1-t.jpg';
import j1 from '../../assets/images/works/j1.jpg';

// Lays 3 music
import k1T from '../../assets/images/works/k1-t.jpg';
import k1 from '../../assets/images/works/k1.jpg';
import k2T from '../../assets/images/works/k2-t.jpg';
import k2 from '../../assets/images/works/k2.jpg';
import k4T from '../../assets/images/works/k4-t.jpg';
import k4 from '../../assets/images/works/k4.jpg';
import k5T from '../../assets/images/works/k5-t.jpg';
import k5 from '../../assets/images/works/k5.jpg';

// Aviko 2 Fiat
import l1T from '../../assets/images/works/l1-t.jpg';
import l1 from '../../assets/images/works/l1.jpg';
import l2T from '../../assets/images/works/l2-t.jpg';
import l2 from '../../assets/images/works/l2.jpg';
import l3T from '../../assets/images/works/l3-t.jpg';
import l3 from '../../assets/images/works/l3.jpg';
import l4T from '../../assets/images/works/l4-t.jpg';
import l4 from '../../assets/images/works/l4.jpg';
import l5T from '../../assets/images/works/l5-t.jpg';
import l5 from '../../assets/images/works/l5.jpg';

// Cheetos 2  museum
import m1T from '../../assets/images/works/m1-t.jpg';
import m1 from '../../assets/images/works/m1.jpg';
import m2T from '../../assets/images/works/m2-t.jpg';
import m2 from '../../assets/images/works/m2.jpg';
import m3T from '../../assets/images/works/m3-t.jpg';
import m3 from '../../assets/images/works/m3.jpg';
import m4T from '../../assets/images/works/m4-t.jpg';
import m4 from '../../assets/images/works/m4.jpg';
import m5T from '../../assets/images/works/m5-t.jpg';
import m5 from '../../assets/images/works/m5.jpg';
import m6T from '../../assets/images/works/m6-t.jpg';
import m6 from '../../assets/images/works/m6.jpg';
import m7T from '../../assets/images/works/m7-t.jpg';
import m7 from '../../assets/images/works/m7.jpg';

// Ranna Rootsi 1 must meri
import n1T from '../../assets/images/works/n1-t.jpg';
import n1 from '../../assets/images/works/n1.jpg';
import n2T from '../../assets/images/works/n2-t.jpg';
import n2 from '../../assets/images/works/n2.jpg';
import n3T from '../../assets/images/works/n3-t.jpg';
import n3 from '../../assets/images/works/n3.jpg';

// Cheetos 3  rock paper paw
import o1T from '../../assets/images/works/o1-t.jpg';
import o1 from '../../assets/images/works/o1.jpg';
import o2T from '../../assets/images/works/o2-t.jpg';
import o2 from '../../assets/images/works/o2.jpg';
import o3T from '../../assets/images/works/o3-t.jpg';
import o3 from '../../assets/images/works/o3.jpg';
import o4T from '../../assets/images/works/o4-t.jpg';
import o4 from '../../assets/images/works/o4.jpg';
import o5T from '../../assets/images/works/o5-t.jpg';
import o5 from '../../assets/images/works/o5.jpg';
import o6T from '../../assets/images/works/o6-t.jpg';
import o6 from '../../assets/images/works/o6.jpg';

// Lays 4 Movies
import p1T from '../../assets/images/works/p1-t.jpg';
import p1 from '../../assets/images/works/p1.jpg';
import p2T from '../../assets/images/works/p2-t.jpg';
import p2 from '../../assets/images/works/p2.jpg';
import p3T from '../../assets/images/works/p3-t.jpg';
import p3 from '../../assets/images/works/p3.jpg';
import p4T from '../../assets/images/works/p4-t.jpg';
import p4 from '../../assets/images/works/p4.jpg';
import p5T from '../../assets/images/works/p5-t.jpg';
import p5 from '../../assets/images/works/p5.jpg';
import p6T from '../../assets/images/works/p6-t.jpg';
import p6 from '../../assets/images/works/p6.jpg';

// Fazer 2 Must leib
import q1T from '../../assets/images/works/q1-t.jpg';
import q1 from '../../assets/images/works/q1.jpg';
import q2T from '../../assets/images/works/q2-t.jpg';
import q2 from '../../assets/images/works/q2.jpg';
import q3T from '../../assets/images/works/q3-t.jpg';
import q3 from '../../assets/images/works/q3.jpg';

// Fazer 3 Jore
import r1T from '../../assets/images/works/r1-t.jpg';
import r1 from '../../assets/images/works/r1.jpg';
import r2T from '../../assets/images/works/r2-t.jpg';
import r2 from '../../assets/images/works/r2.jpg';

// Lays 5 Music
import s1T from '../../assets/images/works/s1-t.jpg';
import s1 from '../../assets/images/works/s1.jpg';
import s2T from '../../assets/images/works/s2-t.jpg';
import s2 from '../../assets/images/works/s2.jpg';
import s3T from '../../assets/images/works/s3-t.jpg';
import s3 from '../../assets/images/works/s3.jpg';
import s4T from '../../assets/images/works/s4-t.jpg';
import s4 from '../../assets/images/works/s4.jpg';
import s5T from '../../assets/images/works/s5-t.jpg';
import s5 from '../../assets/images/works/s5.jpg';
import s6T from '../../assets/images/works/s6-t.jpg';
import s6 from '../../assets/images/works/s6.jpg';

// Salvest 1
import t1T from '../../assets/images/works/t1-t.jpg';
import t1 from '../../assets/images/works/t1.jpg';
import t2T from '../../assets/images/works/t2-t.jpg';
import t2 from '../../assets/images/works/t2.jpg';
import t3T from '../../assets/images/works/t3-t.jpg';
import t3 from '../../assets/images/works/t3.jpg';

// Lays 6 Movies
import u1T from '../../assets/images/works/u1-t.png';
import u1 from '../../assets/images/works/u1.png';
import u2T from '../../assets/images/works/u2-t.png';
import u2 from '../../assets/images/works/u2.png';
import u3T from '../../assets/images/works/u3-t.png';
import u3 from '../../assets/images/works/u3.png';
import u4T from '../../assets/images/works/u4-t.png';
import u4 from '../../assets/images/works/u4.png';
import u5T from '../../assets/images/works/u5-t.png';
import u5 from '../../assets/images/works/u5.png';
import u6T from '../../assets/images/works/u6-t.png';
import u6 from '../../assets/images/works/u6.png';

// Fazer 4 Toast
import v1T from '../../assets/images/works/v1-t.png';
import v1 from '../../assets/images/works/v1.png';
import v2T from '../../assets/images/works/v2-t.png';
import v2 from '../../assets/images/works/v2.png';

// Salvest 2 Uus seest ja väljast
import w1T from '../../assets/images/works/w1-t.png';
import w1 from '../../assets/images/works/w1.png';
import w2T from '../../assets/images/works/w2-t.png';
import w2 from '../../assets/images/works/w2.png';
import w3T from '../../assets/images/works/w3-t.png';
import w3 from '../../assets/images/works/w3.png';

// Seesam 1 Koolitee
import x1T from '../../assets/images/works/x1-t.png';
import x1 from '../../assets/images/works/x1.png';
import x2T from '../../assets/images/works/x2-t.png';
import x2 from '../../assets/images/works/x2.png';
import x3T from '../../assets/images/works/x3-t.png';
import x3 from '../../assets/images/works/x3.png';
import x4T from '../../assets/images/works/x4-t.png';
import x4 from '../../assets/images/works/x4.png';
import x5T from '../../assets/images/works/x5-t.png';
import x5 from '../../assets/images/works/x5.png';

// Lays 7 Music
import y1T from '../../assets/images/works/y1-t.png';
import y1 from '../../assets/images/works/y1.png';
import y2T from '../../assets/images/works/y2-t.png';
import y2 from '../../assets/images/works/y2.png';
import y3T from '../../assets/images/works/y3-t.png';
import y3 from '../../assets/images/works/y3.png';
import y4T from '../../assets/images/works/y4-t.png';
import y4 from '../../assets/images/works/y4.png';
import y5T from '../../assets/images/works/y5-t.png';
import y5 from '../../assets/images/works/y5.png';
import y6T from '../../assets/images/works/y6-t.png';
import y6 from '../../assets/images/works/y6.png';
import y7T from '../../assets/images/works/y7-t.png';
import y7 from '../../assets/images/works/y7.png';

// Lays 8 Movies
import aa1T from '../../assets/images/works/aa1-t.png';
import aa1 from '../../assets/images/works/aa1.png'
import aa2T from '../../assets/images/works/aa2-t.png';
import aa2 from '../../assets/images/works/aa2.png'
import aa3T from '../../assets/images/works/aa3-t.png';
import aa3 from '../../assets/images/works/aa3.png'
import aa4T from '../../assets/images/works/aa4-t.png';
import aa4 from '../../assets/images/works/aa4.png'
import aa5T from '../../assets/images/works/aa5-t.png';
import aa5 from '../../assets/images/works/aa5.png'
import aa6T from '../../assets/images/works/aa6-t.png';
import aa6 from '../../assets/images/works/aa6.png'
import aa7T from '../../assets/images/works/aa7-t.png';
import aa7 from '../../assets/images/works/aa7.png'

// Geisha
import ab1T from "../../assets/images/works/ab1-t.png";
import ab1 from "../../assets/images/works/ab1.png";
import ab2T from "../../assets/images/works/ab2-t.png";
import ab2 from "../../assets/images/works/ab2.png";
import ab3T from "../../assets/images/works/ab3-t.png";
import ab3 from "../../assets/images/works/ab3.png";
import ab4T from "../../assets/images/works/ab4-t.png";
import ab4 from "../../assets/images/works/ab4.png";

// Kodusai - otsi koduseid hetki 2023
import ac1T from "../../assets/images/works/ac1-t.png";
import ac1 from "../../assets/images/works/ac1.png";

// Salvest - supp ja auhind ühe soojaga 2023
import ad1T from "../../assets/images/works/ad1-t.png";
import ad1 from "../../assets/images/works/ad1.png";
import ad2T from "../../assets/images/works/ad2-t.png";
import ad2 from "../../assets/images/works/ad2.png";
import ad3T from "../../assets/images/works/ad3-t.png";
import ad3 from "../../assets/images/works/ad3.png";

// Salvest - Grillpidu on rohkem kui ketšup 2023
import ae1T from "../../assets/images/works/ae1-t.png";
import ae1 from "../../assets/images/works/ae1.png";
import ae2T from "../../assets/images/works/ae2-t.png";
import ae2 from "../../assets/images/works/ae2.png";

// Fazer - Steetfood head maitsed käigu pealt 2023
import af1T from "../../assets/images/works/af1-t.png";
import af1 from "../../assets/images/works/af1.png";
import af2T from "../../assets/images/works/af2-t.png";
import af2 from "../../assets/images/works/af2.png";

// Fazer - Tuttifrutti suvita südamest 2023
import ag1T from "../../assets/images/works/ag1-t.png";
import ag1 from "../../assets/images/works/ag1.png";
import ag2T from "../../assets/images/works/ag2-t.png";
import ag2 from "../../assets/images/works/ag2.png";

// Fazer - must või piimaga 2023
import ah1T from "../../assets/images/works/ah1-t.png";
import ah1 from "../../assets/images/works/ah1.png";
import ah2T from "../../assets/images/works/ah2-t.png";
import ah2 from "../../assets/images/works/ah2.png";

// Tere - Emma 2023
import ai1T from "../../assets/images/works/ai1-t.png";
import ai1 from "../../assets/images/works/ai1.png";
import ai2T from "../../assets/images/works/ai2-t.png";
import ai2 from "../../assets/images/works/ai2.png";

// Geisha - Kuhu süda kutsub 2024
import aj1 from "../../assets/images/works/aj1.png";
import aj2 from "../../assets/images/works/aj2.png";
import aj3 from "../../assets/images/works/aj3.png";
import aj4 from "../../assets/images/works/aj4.png";
import aj1T from "../../assets/images/works/aj1-t.png";
import aj2T from "../../assets/images/works/aj2-t.png";
import aj3T from "../../assets/images/works/aj3-t.png";
import aj4T from "../../assets/images/works/aj4-t.png";

// Fazer - Võida aasta pidu 2024
import ak1 from "../../assets/images/works/ak1.png";
import ak2 from "../../assets/images/works/ak2.png";
import ak1T from "../../assets/images/works/ak1-t.png";
import ak2T from "../../assets/images/works/ak2-t.png";

// Cheetos - Osta cheetos võida ps5 2024
import al1 from "../../assets/images/works/al1.png";
import al2 from "../../assets/images/works/al2.png";
import al3 from "../../assets/images/works/al3.png";
import al4 from "../../assets/images/works/al4.png";
import al5 from "../../assets/images/works/al5.png";
import al6 from "../../assets/images/works/al6.png";
import al1T from "../../assets/images/works/al1-t.png";
import al2T from "../../assets/images/works/al2-t.png";
import al3T from "../../assets/images/works/al3-t.png";
import al4T from "../../assets/images/works/al4-t.png";
import al5T from "../../assets/images/works/al5-t.png";
import al6T from "../../assets/images/works/al6-t.png";

// Fazer Street Food - Kevadine trenni- ja maitsemeele väljakutse 2024
import am1 from "../../assets/images/works/am1.png";
import am2 from "../../assets/images/works/am2.png";
import am1T from "../../assets/images/works/am1-t.png";
import am2T from "../../assets/images/works/am2-t.png";

// Fazer - Küpsista või küpseta 2024
import an1 from "../../assets/images/works/an1.png";
import an2 from "../../assets/images/works/an2.png";
import an1T from "../../assets/images/works/an1-t.png";
import an2T from "../../assets/images/works/an2-t.png";

// Lays - Pole LAy'si, pole mängu 2024
import ao1 from "../../assets/images/works/ao1.png";
import ao2 from "../../assets/images/works/ao2.png";
import ao3 from "../../assets/images/works/ao3.png";
import ao4 from "../../assets/images/works/ao4.png";
import ao5 from "../../assets/images/works/ao5.png";
import ao6 from "../../assets/images/works/ao6.png";
import ao1T from "../../assets/images/works/ao1-t.png";
import ao2T from "../../assets/images/works/ao2-t.png";
import ao3T from "../../assets/images/works/ao3-t.png";
import ao4T from "../../assets/images/works/ao4-t.png";
import ao5T from "../../assets/images/works/ao5-t.png";
import ao6T from "../../assets/images/works/ao6-t.png";
import ao_est_mob from "../../assets/images/works/ao-mob-est.png";
import ao_lit_mob from "../../assets/images/works/ao-mob-lit.png";
import ao_lat_mob from "../../assets/images/works/ao-mob-lat.png";
import ao_est_mobT from "../../assets/images/works/ao-mob-est.png";
import ao_lit_mobT from "../../assets/images/works/ao-mob-lit-t.png";
import ao_lat_mobT from "../../assets/images/works/ao-mob-lat-t.png";

// Tutti Frutti - Maitseb nagu puhkus 2024
import ap1 from "../../assets/images/works/ap1.png";
import ap2 from "../../assets/images/works/ap2.png";
import ap3 from "../../assets/images/works/ap3.png";
import ap1T from "../../assets/images/works/ap1-t.png";
import ap2T from "../../assets/images/works/ap2-t.png";
import ap3T from "../../assets/images/works/ap3-t.png";

// Fazer Šokolaad 2024
import aq1 from "../../assets/images/works/aq1.png";
import aq2 from "../../assets/images/works/aq2.png";
import aq1T from "../../assets/images/works/aq1-t.png";
import aq2T from "../../assets/images/works/aq2-t.png";

// Fazer Street Food 2024 Fall
import ar1 from "../../assets/images/works/ar1.png";
import ar2 from "../../assets/images/works/ar2.png";
import ar1T from "../../assets/images/works/ar1-t.png";
import ar2T from "../../assets/images/works/ar2-t.png";

// Lays Music 2024
import as1 from "../../assets/images/works/as1.png";
import as2 from "../../assets/images/works/as2.png";
import as3 from "../../assets/images/works/as3.png";
import as4 from "../../assets/images/works/as4.png";
import as5 from "../../assets/images/works/as5.png";
import as6 from "../../assets/images/works/as6.png";
import as7 from "../../assets/images/works/as7.png";
import as1T from "../../assets/images/works/as1-t.png";
import as2T from "../../assets/images/works/as2-t.png";
import as3T from "../../assets/images/works/as3-t.png";
import as4T from "../../assets/images/works/as4-t.png";
import as5T from "../../assets/images/works/as5-t.png";
import as6T from "../../assets/images/works/as6-t.png";
import as7T from "../../assets/images/works/as7-t.png";




export const Aviko1 = [{ thumb: a1T, image: a1, caption: 'Aviko: Bon Voyage' }];

export const Cheetos1 = [
  { thumb: b1T, image: b1, caption: 'Cheetos Museum' },
  { thumb: b2T, image: b2, caption: 'Cheetos Museum' },
  { thumb: b3T, image: b3, caption: 'Cheetos Museum' },
  { thumb: b4T, image: b4, caption: 'Cheetos Museum' },
  { thumb: b5T, image: b5, caption: 'Cheetos Museum' },
  { thumb: b6T, image: b6, caption: 'Cheetos Museum' },
];

export const Fazer1 = [
  { thumb: c1T, image: c1, caption: 'Fazer win a tablet' },
  { thumb: c2T, image: c2, caption: 'Fazer win a tablet' },
];

export const Geisha1 = [
  { thumb: d1T, image: d1, caption: 'Geisha x Kokomo' },
  { thumb: d2T, image: d2, caption: 'Geisha x Kokomo' },
];

export const Lays1 = [
  { thumb: e1T, image: e1, caption: 'Lays Movies 1' },
  { thumb: e2T, image: e2, caption: 'Lays Movies 1' },
  { thumb: e3T, image: e3, caption: 'Lays Movies 1' },
];

export const Lays2 = [
  { thumb: f1T, image: f1, caption: 'Lays Football 1' },
  { thumb: f2T, image: f2, caption: 'Lays Football 1' },
  { thumb: f3T, image: f3, caption: 'Lays Football 1' },
];

export const Geisha2 = [
  { thumb: g1T, image: g1, caption: 'Geisha x Tallinn Dolls' },
  { thumb: g2T, image: g2, caption: 'Geisha x Tallinn Dolls' },
];

export const TartuMill1 = [
  { thumb: h1T, image: h1, caption: 'Tartu Mill x Jura' },
];

export const TartuMill2 = [
  { thumb: i1T, image: i1, caption: 'Tartu Mill x Škoda' },
];

export const EestiPagar1 = [
  { thumb: j1T, image: j1, caption: 'Eesti Pagar Sauna' },
];

export const Lays3 = [
  { thumb: k1T, image: k1, caption: 'Lays 3 - Music' },
  { thumb: k2T, image: k2, caption: 'Lays 3 - Music' },
  { thumb: k4T, image: k4, caption: 'Lays 3 - Music' },
  { thumb: k5T, image: k5, caption: 'Lays 3 - Music' },
];

export const Aviko2 = [
  { thumb: l1T, image: l1, caption: 'Aviko 2 x Fiat' },
  { thumb: l2T, image: l2, caption: 'Aviko 2 x Fiat' },
  { thumb: l3T, image: l3, caption: 'Aviko 2 x Fiat' },
  { thumb: l4T, image: l4, caption: 'Aviko 2 x Fiat' },
  { thumb: l5T, image: l5, caption: 'Aviko 2 x Fiat' },
];

export const Cheetos2 = [
  { thumb: m1T, image: m1, caption: 'Cheetos Museum 2' },
  { thumb: m2T, image: m2, caption: 'Cheetos Museum 2' },
  { thumb: m3T, image: m3, caption: 'Cheetos Museum 2' },
  { thumb: m4T, image: m4, caption: 'Cheetos Museum 2' },
  { thumb: m5T, image: m5, caption: 'Cheetos Museum 2' },
  { thumb: m6T, image: m6, caption: 'Cheetos Museum 2' },
  { thumb: m7T, image: m7, caption: 'Cheetos Museum 2' },
];

export const RannaRootsi1 = [
  { thumb: n1T, image: n1, caption: 'Ranna Rootsi Must Meri' },
  { thumb: n2T, image: n2, caption: 'Ranna Rootsi Must Meri' },
  { thumb: n3T, image: n3, caption: 'Ranna Rootsi Must Meri' },
];

export const Cheetos3 = [
  { thumb: o1T, image: o1, caption: 'Cheetos 3 Rock Paper Paw' },
  { thumb: o2T, image: o2, caption: 'Cheetos 3 Rock Paper Paw' },
  { thumb: o3T, image: o3, caption: 'Cheetos 3 Rock Paper Paw' },
  { thumb: o4T, image: o4, caption: 'Cheetos 3 Rock Paper Paw' },
  { thumb: o5T, image: o5, caption: 'Cheetos 3 Rock Paper Paw' },
  { thumb: o6T, image: o6, caption: 'Cheetos 3 Rock Paper Paw' },
];

export const Lays4 = [
  { thumb: p1T, image: p1, caption: 'Lays 4 Movies' },
  { thumb: p2T, image: p2, caption: 'Lays 4 Movies' },
  { thumb: p3T, image: p3, caption: 'Lays 4 Movies' },
  { thumb: p4T, image: p4, caption: 'Lays 4 Movies' },
  { thumb: p5T, image: p5, caption: 'Lays 4 Movies' },
  { thumb: p6T, image: p6, caption: 'Lays 4 Movies' },
];

export const Fazer2 = [
  { thumb: q1T, image: q1, caption: 'Fazer 2 Must leib' },
  { thumb: q2T, image: q2, caption: 'Fazer 2 Must leib' },
  { thumb: q3T, image: q3, caption: 'Fazer 2 Must leib' },
];

export const Fazer3 = [
  { thumb: r1T, image: r1, caption: 'Fazer 3 Jore' },
  { thumb: r2T, image: r2, caption: 'Fazer 3 Jore' },
];

export const Lays5 = [
  { thumb: s1T, image: s1, caption: 'Lays 5 Music' },
  { thumb: s2T, image: s2, caption: 'Lays 5 Music' },
  { thumb: s3T, image: s3, caption: 'Lays 5 Music' },
  { thumb: s4T, image: s4, caption: 'Lays 5 Music' },
  { thumb: s5T, image: s5, caption: 'Lays 5 Music' },
  { thumb: s6T, image: s6, caption: 'Lays 5 Music' },
];

export const Salvest1 = [
  { thumb: t1T, image: t1, caption: 'Salvest 1' },
  { thumb: t2T, image: t2, caption: 'Salvest 1' },
  { thumb: t3T, image: t3, caption: 'Salvest 1' },
];

export const Lays6 = [
  { thumb: u1T, image: u1, caption: 'Lays 6 Movies' },
  { thumb: u2T, image: u2, caption: 'Lays 6 Movies' },
  { thumb: u3T, image: u3, caption: 'Lays 6 Movies' },
  { thumb: u4T, image: u4, caption: 'Lays 6 Movies' },
  { thumb: u5T, image: u5, caption: 'Lays 6 Movies' },
  { thumb: u6T, image: u6, caption: 'Lays 6 Movies' },
];

export const Fazer4 = [
  { thumb: v1T, image: v1, caption: 'Fazer Toast' },
  { thumb: v2T, image: v2, caption: 'Fazer Toast' },
];

export const Salvest2 = [
  { thumb: w1T, image: w1, caption: 'Salvest Uus seest ja väljast' },
  { thumb: w2T, image: w2, caption: 'Salvest Uus seest ja väljast' },
  { thumb: w3T, image: w3, caption: 'Salvest Uus seest ja väljast' },
];

export const Seesam1 = [
  { thumb: x1T, image: x1, caption: 'Seesam Turvalise koolitee test' },
  { thumb: x2T, image: x2, caption: 'Seesam Turvalise koolitee test' },
  { thumb: x3T, image: x3, caption: 'Seesam Turvalise koolitee test' },
  { thumb: x4T, image: x4, caption: 'Seesam Turvalise koolitee test' },
  { thumb: x5T, image: x5, caption: 'Seesam Turvalise koolitee test' },
];

export const Lays7 = [
  { thumb: y1T, image: y1, caption: 'Lays 2022 Music' },
  { thumb: y2T, image: y2, caption: 'Lays 2022 Music' },
  { thumb: y3T, image: y3, caption: 'Lays 2022 Music' },
  { thumb: y4T, image: y4, caption: 'Lays 2022 Music' },
  { thumb: y5T, image: y5, caption: 'Lays 2022 Music' },
  { thumb: y6T, image: y6, caption: 'Lays 2022 Music' },
  { thumb: y7T, image: y7, caption: 'Lays 2022 Music' },
];

export const Lays8 = [
  { thumb: aa1T, image: aa1, caption: "Lays 2023 Movies" },
  { thumb: aa2T, image: aa2, caption: "Lays 2023 Movies" },
  { thumb: aa3T, image: aa3, caption: "Lays 2023 Movies" },
  { thumb: aa4T, image: aa4, caption: "Lays 2023 Movies" },
  { thumb: aa5T, image: aa5, caption: "Lays 2023 Movies" },
  { thumb: aa6T, image: aa6, caption: "Lays 2023 Movies" },
  { thumb: aa7T, image: aa7, caption: "Lays 2023 Movies" },
];

export const Geisha3 = [
  { thumb: ab1T, image: ab1, caption: "Geisha lennutab Jaapanisse" },
  { thumb: ab2T, image: ab2, caption: "Geisha lennutab Jaapanisse" },
  { thumb: ab3T, image: ab3, caption: "Geisha lennutab Jaapanisse" },
  { thumb: ab4T, image: ab4, caption: "Geisha lennutab Jaapanisse" },
];

export const Kodusai1 = [
  { thumb: ac1T, image: ac1, caption: "Rohkem koduseid hetki" },
];

export const Salvest3 = [
  { thumb: ad1T, image: ad1, caption: "Supp ja auhind ühe soojaga" },
  { thumb: ad2T, image: ad2, caption: "Supp ja auhind ühe soojaga" },
  { thumb: ad3T, image: ad3, caption: "Supp ja auhind ühe soojaga" },
];

export const Salvest4 = [
  { thumb: ae1T, image: ae1, caption: "Grillpidu on rohkem kui ketšup" },
  { thumb: ae2T, image: ae2, caption: "Grillpidu on rohkem kui ketšup" },
];

export const Fazer5 = [
  { thumb: af1T, image: af1, caption: "Head maitsed käigu pealt" },
  { thumb: af2T, image: af2, caption: "Head maitsed käigu pealt" },
];

export const Fazer6 = [
  { thumb: ag1T, image: ag1, caption: "Suvita südamest" },
  { thumb: ag2T, image: ag2, caption: "Suvita südamest" },
];

export const Fazer7 = [
  { thumb: ah1T, image: ah1, caption: "Must või piimaga" },
  { thumb: ah2T, image: ah2, caption: "Must või piimaga" },
];

export const Tere1 = [
  { thumb: ai1T, image: ai1, caption: "Emma" },
  { thumb: ai2T, image: ai2, caption: "Emma" },
];

export const Geisha4 = [
  { thumb: aj1T, image: aj1, caption: "Geisha" },
  { thumb: aj2T, image: aj2, caption: "Geisha" },
  { thumb: aj3T, image: aj3, caption: "Geisha" },
  { thumb: aj4T, image: aj4, caption: "Geisha" },
];

export const Fazer8 = [
  { thumb: ak1T, image: ak1, caption: "Fazer" },
  { thumb: ak2T, image: ak2, caption: "Fazer" },
];

export const Cheetos4 = [
  { thumb: al1T, image: al1, caption: "Cheetos" },
  { thumb: al2T, image: al2, caption: "Cheetos" },
  { thumb: al3T, image: al3, caption: "Cheetos" },
  { thumb: al4T, image: al4, caption: "Cheetos" },
  { thumb: al5T, image: al5, caption: "Cheetos" },
  { thumb: al6T, image: al6, caption: "Cheetos" },
];

export const Fazer9 = [
  { thumb: am1T, image: am1, caption: "Fazer"},
  { thumb: am2T, image: am2, caption: "Fazer"},
];

export const Fazer10 = [
  { thumb: an1T, image: an1, caption: "Fazer"},
  { thumb: an2T, image: an2, caption: "Fazer"},
];

export const Lays9 = [
  { thumb: ao1T, image: ao1, caption: "Lays" },
  { thumb: ao2T, image: ao2, caption: "Lays" },
  { thumb: ao_est_mobT, image: ao_est_mob, caption: "Lays" },
  { thumb: ao3T, image: ao3, caption: "Lays" },
  { thumb: ao4T, image: ao4, caption: "Lays" },
  { thumb: ao_lit_mobT, image: ao_lit_mob, caption: "Lays" },
  { thumb: ao5T, image: ao5, caption: "Lays" },
  { thumb: ao6T, image: ao6, caption: "Lays" },
  { thumb: ao_lat_mobT, image: ao_lat_mob, caption: "Lays" },
];

export const Tuttifrutti2 = [
  { thumb: ap1T, image: ap1, caption: "Tuttifrutti" },
  { thumb: ap2T, image: ap2, caption: "Tuttifrutti" },
  { thumb: ap3T, image: ap3, caption: "Tuttifrutti" },
];

export const Fazer11 = [
  { thumb: aq1T, image: aq1, caption: "Fazer"},
  { thumb: aq2T, image: aq2, caption: "Fazer"},
];

export const Fazer12 = [
  { thumb: ar1T, image: ar1, caption: "Fazer"},
  { thumb: ar2T, image: ar2, caption: "Fazer"},
];

export const Lays10 = [
  { thumb: as1T, image: as1, caption: "Lays" },
  { thumb: as2T, image: as2, caption: "Lays" },
  { thumb: as3T, image: as3, caption: "Lays" },
  { thumb: as4T, image: as4, caption: "Lays" },
  { thumb: as5T, image: as5, caption: "Lays" },
  { thumb: as6T, image: as6, caption: "Lays" },
  { thumb: as7T, image: as7, caption: "Lays" },
];


